import type { AppEndPointBuilder } from '../apiSlice';
import { Media } from '../../../../models/CustomerReturn';

export interface ProductInfo {
  isAssemblyRequired: boolean;
  itemType?: string;
  media?: Media;
  name?: string;
  productNumber: string;
  type?: string;
  unitCode: 'PIECE' | 'METER';
}

type GetProductInfoResponse = ProductInfo[];

interface GetProductInfoApiConfig {
  countryCode: string,
  languageCode: string,
  productNumbers: string,
}

export const getProductInfoBuilder = (builder: AppEndPointBuilder) => builder.query<
GetProductInfoResponse, GetProductInfoApiConfig>({
  query: ({ countryCode, languageCode, productNumbers }) => ({
    method: 'GET',
    url: `v1/${countryCode}/${languageCode}/products?productNumbers=${productNumbers}`,
  }),
});
