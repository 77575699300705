import InlineMessage from '@ingka/inline-message';
import Loading, { LoadingBall } from '@ingka/loading';
import Text from '@ingka/text';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { Card, useScrollIntoView } from 'shared-frontend';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { CardTitle } from '../CardTitle/CardTitle';
import ArticleListItem from './ArticleListItem/ArticleListItem';
import {
  GroupedProducts,
  reduceGroupedProducts,
} from './reduceGroupedProducts';
import { useProductInformation } from './useProductInformation';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import TooltipWithCondition from '../Tooltips/TooltipWithCondition';
import { CollapsibleButton, CollapsibleProvider, CollapsibleSection } from '../CollapsibleSection/CollapsibleSection';

function ArticlesReasonCard() {
  const { t } = useTranslation();
  const showStockCorrection = useGetAppState('showStockCorrection');
  const cardRef = useRef<HTMLDivElement>(null);

  useScrollIntoView(cardRef, showStockCorrection, {
    block: 'start',
  });

  const { enrichedProducts, isError, isLoading } = useProductInformation();
  const groupedProducts = enrichedProducts?.reduce<GroupedProducts>(reduceGroupedProducts, {});
  const showLoader = isLoading || !groupedProducts;

  const ariaLabelledById = 'article-reason-title-id';

  return (
    <Card
      ariaLabelledById={ariaLabelledById}
      cardRef={cardRef}
      className="pb-0 mx-0"
      title={(
        <CardTitle className="px-2 m:px-6 l:px-10" id={ariaLabelledById}>
          {t('articles-reason-card.title')}
        </CardTitle>
      )}
      horizontalPadding={false}
    >
      {isError && (
        <InlineMessage
          body={t('articles-reason-card.errorFetchingProductInfo')}
          className="mx-2 m:mx-6 l:mx-10 mb-6 w-fit"
          ssrIcon={informationCircle}
          variant="negative"
        />
      )}
      {showLoader ? (
        <Loading data-testid="loader" text={t('commonly-reused.loading')}>
          <LoadingBall />
        </Loading>
      ) : (
        <>
          {Object.keys(groupedProducts).map((group) => {
            const {
              groupTranslation, groupTooltipTranslation, orderNumber, products, receiptNumber,
            } = groupedProducts[group];
            const translationOptions = { orderNumber, receiptNumber };
            const purchaseName = t(`articles-reason-card.groups.${groupTranslation}`, translationOptions);

            return (
              <CollapsibleProvider collapsibleSectionId={`collapse-${group}`} key={group}>
                <div className="border-y-[1px] border-neutral-grey-200 flex px-2 m:px-4 l:px-10 py-0 bg-neutral-grey-100 min-h-10 items-center">
                  <TooltipWithCondition
                    describedById={`tooltip-${group}-id`}
                    tooltipText={groupTooltipTranslation ? t(groupTooltipTranslation) : null}
                  >
                    <Text className="pl-2" headingSize="s">
                      {purchaseName}
                    </Text>
                  </TooltipWithCondition>
                  <CollapsibleButton
                    ariaPurchaseName={purchaseName}
                    disabled={false}
                  />
                </div>
                <CollapsibleSection>
                  <ul className="pt-2 pb-7">
                    {products.map((article) => (
                      <ArticleListItem
                        key={article.id}
                        article={article}
                      />
                    ))}
                  </ul>
                </CollapsibleSection>
              </CollapsibleProvider>
            );
          })}
        </>
      )}
    </Card>

  );
}

export default ArticlesReasonCard;
