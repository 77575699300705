import { IssueCategory } from '@resolutions/condition-of-goods';
import Button from '@ingka/button';
import { ModalBody } from '@ingka/modal';
import { IssueType } from '../../../store/features/OTCSlice/reducerUtils';
import { useSetArticleIssue } from '../../../store/features/OTCSlice/OTCSlice';

export const changeIssue = {
  actor: 'IKEA',
  category: IssueCategory.CHANGE_OF_MIND,
  id: '1.5.1',
  mainReasonCode: '05',
  subReasonCode: '01',
  title: 'Style or Size does not fit',
};

const damagedIssue = {
  actor: 'IKEA',
  category: IssueCategory.DAMAGED,
  id: '1.1.3',
  mainReasonCode: '85',
  subReasonCode: '04',
  title: 'Handling damaged by IKEA',
};

const generalIssue = {
  actor: 'IKEA',
  category: IssueCategory.GENERAL_COMPLAINT,
  id: '3.7.1',
  mainReasonCode: '00',
  subReasonCode: '00',
  title: 'Not satisfied with experience and service',
};

export function OTCIssuePickerBody() {
  const setArticleIssue = useSetArticleIssue();

  return (
    <ModalBody className="flex flex-col gap-4">
      <Button
        data-testid="ISSUE_ADD_CHANGE"
        disabled={false}
        onClick={() => setArticleIssue(changeIssue)}
        text="Change of mind"
        type="primary"
      />
      <Button
        data-testid="ISSUE_ADD_DAMAGED"
        disabled={false}
        onClick={() => setArticleIssue(damagedIssue)}
        text="Damaged"
        type="primary"
      />
      <Button
        data-testid="ISSUE_ADD_GENERAL"
        disabled={false}
        onClick={() => setArticleIssue(generalIssue)}
        text="General complaint"
        type="primary"
      />
      <Button
        data-testid="ISSUE_RESET"
        disabled={false}
        onClick={() => setArticleIssue(null as unknown as IssueType)}
        text="Reset issue"
        type="secondary"
      />
    </ModalBody>
  );
}
