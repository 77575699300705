import Button from '@ingka/button';
import Pill from '@ingka/pill';
import { useTranslation } from 'react-i18next';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { useSubmitReturn } from '../../../hooks/useSubmitReturn/useSubmitReturn';
import { CreateRouteOnly, ViewRouteOnly } from '../../RouteOnly/RouteOnly';
import { ShowMoreMenu } from '../ShowMoreMenu/ShowMoreMenu';
import { EstimatedRefund } from './EstimatedRefund';
import {
  useGetAppState,
  useGetShowSaveCustomerReturnButton,
  useHasStockCorrectionItems,
  useToggleStockCorrection,
} from '../../../store/features/appStateSlice/appStateSlice';
import SaveReturnButton from './SaveReturnButton';
import TooltipWithCondition from '../../Tooltips/TooltipWithCondition';

function BottomBar() {
  const { t } = useTranslation();
  const {
    isMutationLoading,
    isSubmitButtonDisabled,
    returnReference,
    submitReturn,
  } = useSubmitReturn();
  const returnReferenceText = returnReference ?? '-';
  const showSaveCustomerReturnButton = useGetShowSaveCustomerReturnButton();
  const showStockCorrection = useGetAppState('showStockCorrection');
  const hasStockCorrectionItems = useHasStockCorrectionItems();
  const handleToggleStockCorrection = useToggleStockCorrection();

  return (
    <div data-testid="bottom-bar" className="grid grid-cols-[minmax(0,_max-content)_minmax(0,_auto)minmax(0,_max-content)] h-[56px] px-[31.37px] py-2 bg-default border-b-[1px] border-neutral-grey-200">
      <div className="col-start-1 col-end2 grid grid-cols-[minmax(32px,_max-content)_minmax(0,_max-content)] items-center gap-3">
        {/* back button and case */}
        <TooltipWithCondition
          describedById="tooltip-return-number-id"
          tooltipText={t('control-bar.returnNumberTooltip')}
        >
          <span className="col-start-2 col-end-3 text-sm text-dark font-bold">{returnReferenceText}</span>
        </TooltipWithCondition>
      </div>
      <div className="hidden m:flex justify-center items-center text-center col-start-2 col-end-3 gap-2">
        <EstimatedRefund />
      </div>
      <div className="col-start-3 col-end-4 flex gap-3">
        <ViewRouteOnly>
          <>
            {hasStockCorrectionItems && (
              <TooltipWithCondition
                describedById="tooltip-stock-correction-button-id"
                tooltipText={t('control-bar.button.stockCorrectionTooltip')}
              >
                <Pill
                  label={t('control-bar.button.stockCorrection')}
                  className="col-start-1 col-end-2"
                  onClick={handleToggleStockCorrection}
                  selected={showStockCorrection}
                  ssrIcon={informationCircle}
                  size="small"
                />
              </TooltipWithCondition>
            )}
            {showSaveCustomerReturnButton && (<SaveReturnButton />)}
          </>
        </ViewRouteOnly>
        <CreateRouteOnly>
          <Button
            onClick={submitReturn}
            text={t('control-bar.button.submitReturn')}
            className="col-start-1 col-end-2"
            type="emphasised"
            size="small"
            loading={isMutationLoading}
            disabled={isSubmitButtonDisabled}
          />
        </CreateRouteOnly>
        <ShowMoreMenu />
      </div>
    </div>

  );
}

export default BottomBar;
