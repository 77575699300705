import Checkbox from '@ingka/checkbox';
import FormField from '@ingka/form-field';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { useTranslation } from 'react-i18next';
import {
  DataCardConfig,
  DataCardConfigColumn,
  formatTimeWIndowToStrArr,
  IsomReturnOption,
  PriceItem,
  SelectedReturnMethod,
  TaxKeys,
} from 'shared-frontend';
import {
  useGetAppState,
  useGetEnabledFeature,
} from '../../store/features/appStateSlice/appStateSlice';
import {
  useGetSelectedReturnMethodState,
} from '../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useGoToSidebarPage } from '../../store/features/sidebar/sidebarSlice';
import { useMarketConfigFetch } from '../../useMarketConfigFetch';
import { useMarketLocale } from '../../hooks/useMarketLocale';
import { CreateRouteOnly } from '../RouteOnly/RouteOnly';
import { useApplicableActions } from '../../hooks/useApplicableActions';
import { useIsReturnClosed } from '../../hooks/useIsReturnClosed';
import TooltipWithCondition from '../Tooltips/TooltipWithCondition';
import { useQuestionnaireResponse } from '../app/initialFetchUtils';
import { PaidBy } from '../../models/CustomerReturn';

type ReturnMethodData = {
  name: string,
  description: string,
  confirmationInfo: string,
  tspData: string,
  selectedDate?: string,
  selectedTime?: string,
  selectedTimeDescription?: string,
  questionnaireStatusLabel: string,
  questionnaireBtnText: string,
  timeWindowBtnText: string,
  price: number,
  paidBy: PaidBy,
  labels: number;
  noSelectedReturnMethod?: string;
  holdUntilPaid?: string;
};

type ContentAndConfig = {
  config: DataCardConfigColumn<ReturnMethodData>[];
  content: ReturnMethodData;
  editDisabled: boolean;
  editLoading: boolean;
  selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> | null;
};

export const useReturnMethodConfig = (): ContentAndConfig => {
  const { t } = useTranslation();
  const customerReturn = useGetAppState('customerReturn');
  const returnReference = useGetAppState('returnReference');
  const articlesWithIncorrectUnit = useGetAppState('articlesWithIncorrectUnit');
  const showPaidByTsp = useGetEnabledFeature('showPaidByTsp');

  const editLoading = articlesWithIncorrectUnit === null;
  const hasArticlesWithIncorrectUnit = articlesWithIncorrectUnit
    && articlesWithIncorrectUnit.length > 0;
  let editDisabledNoCommunicationOptions = false;
  if (customerReturn) {
    const {
      customer: {
        deliveryInfo: {
          email, mobile, phone,
        },
      },
    } = customerReturn;
    editDisabledNoCommunicationOptions = !email && !mobile && !phone;
  }
  const editDisabled = Boolean(!!returnReference || editLoading
    || hasArticlesWithIncorrectUnit || editDisabledNoCommunicationOptions);

  const selectedReturnMethod = useGetSelectedReturnMethodState('selectedReturnMethod');
  const { data: Qresp } = useQuestionnaireResponse();
  const hasQuestionnaire = Boolean(
    selectedReturnMethod
    && Object.keys(Qresp?.questionnaire || {}).includes(selectedReturnMethod?.id),
  );
  const { countryCode, languageCode } = useMarketLocale();
  const questionnaire = useGetSelectedReturnMethodState('questionnaireCompleted');
  const goToTimeWindowPage = useGoToSidebarPage('time-window-calendar');
  const goToQuestionnairePage = useGoToSidebarPage('questionnaire');
  const { data: marketConfig } = useMarketConfigFetch();
  const purchaseInformation = marketConfig?.purchaseInformation;
  const priceKey = purchaseInformation?.itemPriceExcludesTax
    ? TaxKeys.priceExclTax
    : TaxKeys.priceInclTax;
  const price = selectedReturnMethod?.price[priceKey] as number;
  const isQuestionnaireValid = !!questionnaire;
  const showHoldUntilPaid = selectedReturnMethod?.marketConfig?.holdUntilPaid?.enabled
    && typeof price === 'number'
    && price > 0
    && customerReturn?.paidBy === 'CUSTOMER'
    && !selectedReturnMethod?.customerPaymentReceived;

  const { actions } = useApplicableActions();
  const isReturnClosed = useIsReturnClosed();

  const tspName = selectedReturnMethod?.timeWindow?.tspData?.name;
  const tspID = selectedReturnMethod?.timeWindow?.tspData?.id;

  const content: ReturnMethodData = {
    name: selectedReturnMethod?.name ?? '',
    description: selectedReturnMethod?.id ? t(`returnOptions.${selectedReturnMethod?.id}.description`) : '',
    confirmationInfo: editDisabled ? t('return-method-card.returnMethod.confirmationMessage.sent') : t('return-method-card.returnMethod.confirmationMessage.notSent'),
    tspData: tspName ? `${tspName}${tspID ? `, ${t('return-method-card.returnMethod.confirmationMessage.tspId', { tspID })}` : ''}` : '',
    questionnaireStatusLabel: isQuestionnaireValid ? t('return-method-card.other.questionnaire.completed') : t('return-method-card.other.questionnaire.notCompleted'),
    questionnaireBtnText: t('return-method-card.other.questionnaire.button'),
    timeWindowBtnText: t('return-method-card.other.timeWindow.editBtn'),
    price,
    paidBy: customerReturn?.paidBy ?? 'INVALID',
    labels: Number(customerReturn?.numberOfLabels),
    holdUntilPaid: selectedReturnMethod?.marketConfig?.holdUntilPaid?.expirationString,
  };

  const otherColumn: DataCardConfigColumn<ReturnMethodData> = {
    title: t('return-method-card.other.title'),
    rows: [
      {
        accessor: 'labels',
        valueRender: (labels) => (
          <TooltipWithCondition
            describedById="tooltip-return-method-other-id"
            tooltipText={t('return-method-card.other.labelsTooltip')}
          >
            <span data-testid="labelsText">{`${t('return-method-card.other.labels')}: ${labels}`}</span>
          </TooltipWithCondition>
        ),
      },
    ],
  };

  const priceColumn: DataCardConfigColumn<ReturnMethodData> = {
    title: t('return-method-card.priceColumn.title'),
    rows: [{
      accessor: 'paidBy',
      valueRender: (paidBy) => {
        const ikeaId = 'PAID_BY_IKEA';
        const tspId = 'PAID_BY_TSP';
        return (
          <>
            <div className="flex ml-2 gap-2 xl:justify-end">
              <FormField>
                <Checkbox
                  id={ikeaId}
                  value={ikeaId}
                  checked={paidBy === 'IKEA' || paidBy === 'INVALID'}
                  disabled
                  label={t('return-method-card.priceColumn.paidByIkea')}
                  className="[&.checkbox_input[type=checkbox]:disabled_~_.checkbox\_\_label_label]:!text-lightest"
                />
                {showPaidByTsp
                  && (
                    <Checkbox
                      id={tspId}
                      value={tspId}
                      checked={paidBy === 'TSP' || paidBy === 'INVALID'}
                      disabled
                      label={t('return-method-card.priceColumn.paidByTsp')}
                      className="[&.checkbox_input[type=checkbox]:disabled_~_.checkbox\_\_label_label]:!text-lightest"
                    />
                  )}
              </FormField>
            </div>
            {paidBy === 'INVALID' && (
              <InlineMessage
                className="text-wrap flex ml-2 gap-2 xl:justify-end"
                variant="negative"
                ssrIcon={informationCircle}
                body={t('return-method-card.priceColumn.paidByInvalid')}
              />
            )}
          </>
        );
      },
    }],
  };

  if (!selectedReturnMethod) {
    content.noSelectedReturnMethod = 'noSelectedReturnMethod';
    let inlineMessageBody = '';
    if (articlesWithIncorrectUnit && articlesWithIncorrectUnit.length > 0) {
      inlineMessageBody = t('return-method-card.returnMethod.editDisabledArticleUnitsMismatch');
    } else if (articlesWithIncorrectUnit && articlesWithIncorrectUnit.length === 0) {
      inlineMessageBody = t('return-method-card.returnMethod.noMethodSelected');
    }

    const configNoReturnMethod: DataCardConfigColumn<ReturnMethodData>[] = [
      inlineMessageBody ? {
        rows: [{
          accessor: 'noSelectedReturnMethod',
          valueRender: () => (
            <InlineMessage
              subtle
              variant="negative"
              ssrIcon={informationCircle}
              body={inlineMessageBody}
              className="mt-4"
            />
          ),
        }],
      } : {},
      otherColumn,
      {},
      priceColumn,
    ];

    return {
      content,
      config: configNoReturnMethod,
      editDisabled,
      editLoading,
      selectedReturnMethod,
    };
  }

  const config: DataCardConfigColumn<ReturnMethodData>[] = [];
  config.push({
    title: t(`returnOptions.${selectedReturnMethod.id}.title`),
    rows: [{
      accessor: 'description',
    },
    {
      accessor: 'confirmationInfo',
    },
    {
      accessor: 'tspData',
    },
    ],
  });
  if (showHoldUntilPaid && config[0]?.rows) {
    config[0].rows.push({
      accessor: 'holdUntilPaid',
      valueRender: (text) => (
        <InlineMessage
          subtle
          variant="negative"
          ssrIcon={informationCircle}
          body={text as string}
          className="mt-4 break-normal"
        />
      ),
    });
  }

  if (selectedReturnMethod.timeWindow && selectedReturnMethod.isPickUp) {
    const selectedTimeDescription: DataCardConfig<ReturnMethodData> = {
      accessor: 'selectedTimeDescription',
    };
    config.push({
      title: t('return-method-card.selectedTime.title'),
      rows: [
        {
          accessor: 'selectedDate',
        },
        {
          accessor: 'selectedTime',
        },
        ...(!editDisabled ? [selectedTimeDescription] : []),
        {
          accessor: 'timeWindowBtnText',
          valueRender: (text) => {
            const hasRescheduleAction = actions?.some((action) => action === 'RE_SCHEDULE' || action === 'RE_SCHEDULE_FAILED');
            if (hasRescheduleAction) {
              return (
                <Button
                  className="mt-3"
                  onClick={goToTimeWindowPage}
                  text={t('return-method-card.selectedTime.reschedule')}
                  size="small"
                />
              );
            }

            return (
              <CreateRouteOnly>
                <Button
                  className="mt-3"
                  disabled={editDisabled}
                  text={text as string}
                  onClick={goToTimeWindowPage}
                  size="small"
                />
              </CreateRouteOnly>
            );
          },
        },
      ],
    });
    const [formattedDate, formattedTime] = formatTimeWIndowToStrArr(
      languageCode,
      countryCode,
      selectedReturnMethod.timeWindow.fromDateTime,
      selectedReturnMethod.timeWindow.toDateTime,
    );
    content.selectedDate = formattedDate;
    content.selectedTime = formattedTime;
    content.selectedTimeDescription = t('return-method-card.selectedTime.description');
  }

  if (hasQuestionnaire && !isReturnClosed) {
    otherColumn.rows = [
      ...otherColumn.rows as DataCardConfig<ReturnMethodData>[],
      { accessor: 'questionnaireStatusLabel' },
      {
        accessor: 'questionnaireBtnText',
        valueRender: (text) => (
          <Button
            className="mt-3"
            iconPosition="trailing"
            text={text as string}
            type={isQuestionnaireValid ? 'secondary' : 'primary'}
            onClick={goToQuestionnairePage}
            size="small"
          />
        ),
      },
    ];
  }
  config.push(otherColumn);

  // to always have the price-column below to the far right
  if (config.length === 2) {
    config.push({});
  }

  const { price: { currencyCode } } = selectedReturnMethod;
  if (currencyCode) {
    priceColumn.rows?.unshift({
      accessor: 'price',
      valueRender: (amount) => (typeof amount === 'number' && amount > 0 ? (
        <div className="m-2">

          <PriceItem
            locale={`${languageCode}-${countryCode}`}
            currencyCode={currencyCode}
            amount={amount as number}
            size="medium"
            className="l:m-5"
          />
        </div>
      ) : (
        <span className="text-xl font-bold text-dark">
          {t('commonly-reused.free')}
        </span>
      )),
    });
  }

  config.push(priceColumn);

  return {
    content,
    config,
    editDisabled,
    editLoading,
    selectedReturnMethod,
  };
};
