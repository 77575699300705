import { useRoute } from 'wouter';
import { useMarketLocale } from './useMarketLocale';

export const embeddedUrlMap = {
  CREATE: '/create',
  VIEW: '/view',
};

export function getReturnIdentifierFromUrl(url: string) {
  const match = url.match(/\/view\/([^/]+)/);
  const returnId = match ? match[1] : null;
  return returnId;
}

export default function useAppRoutes() {
  const { countryCode, languageCode } = useMarketLocale();
  const { CREATE, VIEW } = embeddedUrlMap;
  const root = `/${countryCode.toLocaleLowerCase()}/${languageCode}`;

  return {
    base: `${root}/returns`,
    create: CREATE,
    embedded: '/embedded',
    otc: {
      root: '/',
      seed: '/pr/:seed',
      submit: '/submit',
    },
    view: `${VIEW}/:rest*`,
    // but in prod the router scopes them
    // we need this for the hooks because in tests the paths are absolute
    urlWildCard: '/:country?/:lang?/:base?/:embedded?',
  };
}

export const useCreateRoute = () => {
  const { create, urlWildCard } = useAppRoutes();
  const [match] = useRoute(`${urlWildCard}${create}/`);
  return match;
};

export const useViewRoute = () => {
  const { view, urlWildCard } = useAppRoutes();
  const [match] = useRoute(`${urlWildCard}${view}`);
  return match;
};

export const useOTCRoute = () => {
  const { otc } = useAppRoutes();
  const [isOTCRoot] = useRoute(otc.root);
  const [isOTCSeed] = useRoute(otc.seed);

  return isOTCRoot || isOTCSeed;
};
