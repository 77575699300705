import ListView from '@ingka/list-view';
import { ModalBody } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import { useGetOTCState } from '../../../store/features/OTCSlice/OTCSlice';
import { isPoPItem } from '../../../store/features/OTCSlice/reducerUtils';
import { useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';
import { ListItem } from '../ListItem';

export function OTCBlindReturnActionsBody() {
  const goToConditionOfGoodsSidebar = useGoToSidebarPage('otc-condition-of-goods');
  const goToIssueSidebar = useGoToSidebarPage('otc-issue-picker');
  const stateBlindReturn = useGetOTCState('blindReturn');
  const { t } = useTranslation();
  const isIssueDisabled = !stateBlindReturn.items?.some(
    (item) => isPoPItem(item) && item.checked,
  );
  const isSetConditionOfGoodsDisabled = stateBlindReturn.items?.every(
    (item) => (isPoPItem(item)
      ? !item.checked || item.itemType === 'SGR' || !item.issue
      : true),
  );

  return (
    <ModalBody>
      <ListView
        id="selectedBlindReturnArticlesActions"
        showDivider={false}
      >
        <ListItem
          data-testid="bulk-select-blindReturn-issue"
          disabled={isIssueDisabled}
          key="BULK_SELECT_ISSUE"
          onClick={goToIssueSidebar}
          title={t('otc-table-actions.bulkSelectIssue')}
        />
        <ListItem
          data-testid="bulk-select-blindReturn-condition-of-goods"
          disabled={isSetConditionOfGoodsDisabled}
          key="BULK_SELECT_CONDITION_GOODS"
          onClick={goToConditionOfGoodsSidebar}
          title={t('otc-table-actions.bulkSelectConditionOfGoods')}
        />
      </ListView>
    </ModalBody>
  );
}
