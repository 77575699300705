import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  createMarketLocale,
  enrichReturnMethodWithMarketConfig,
  InternalQuestionnaireState,
  IsomReturnOption,
  ReturnMethodsMarketConfig,
  SelectedReturnMethod,
} from 'shared-frontend';
import i18next from 'i18next';
import { apiSlice } from '../api/apiSlice';
import {
  matchQuestionnaireTypeToApiResponse,
  mergeQuestionnaireAnswers,
  questionnaireAnswersToCompleted,
  SelectedReturnMethodState,
} from './reducerUtils';

export const addApiMatchers = (builder: ActionReducerMapBuilder<SelectedReturnMethodState>) => {
  builder
    .addMatcher(apiSlice.endpoints.getCustomerReturn.matchFulfilled, (state, action) => {
      const {
        id,
        timeWindow,
        price,
        originalPrice,
      } = action.payload.returnAgreement.returnSettlement.returnOption;
      const tspData = action.payload.returnAgreement.returnSettlement
        .returnOption?.metadata?.timeWindow?.tspData;
      const questionnaire = action.payload.customer?.questionnaire;
      const { receivingBusinessUnit, returnAgreement } = action.payload;
      const taxationInfo = returnAgreement.returnSettlement.returnOption?.taxationInfo;

      const selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> = {
        id,
        isPickUp: (
          state.returnMethodsMarketConfig?.[id]?.isPickUp
        ) || false,
        name: i18next.t(`returnOptions.${id}.title`) || '',
        price,
        originalPrice,
        ...(timeWindow && {
          timeWindow: {
            timeWindowId: timeWindow.id,
            fromDateTime: timeWindow.fromDateTime,
            toDateTime: timeWindow.toDateTime,
            tspData,
          },
        }),
        receivingBusinessUnit,
        taxationInfo: taxationInfo || '',
      };

      const returnMethodMarketConfig = state.returnMethodsMarketConfig;
      const { countryCode, languageCode } = createMarketLocale(i18next.language);

      const enrichedMethod = returnMethodMarketConfig ? enrichReturnMethodWithMarketConfig({
        method: selectedReturnMethod,
        returnMethodMarketConfig,
        holdUntilPaidConfig: {
          label: i18next?.t('holdUntilPaid.returnOptionNotification'),
          languageCode,
          countryCode,
        },
      }) : selectedReturnMethod;

      if (enrichedMethod.marketConfig?.holdUntilPaid?.enabled) {
        const isPaid = action.payload.statusViewModel.find(
          (step) => step.step === 'PAID',
        )?.stepStatus === 'SUCCESS';
        enrichedMethod.customerPaymentReceived = Boolean(isPaid);
      }

      state.selectedReturnMethod = enrichedMethod;
      if (selectedReturnMethod.timeWindow) {
        state.draft.reschedule = {
          originalTimeWindow: selectedReturnMethod.timeWindow,
        };
      }
      state.questionnaireCompleted = questionnaireAnswersToCompleted(questionnaire);
    })
    .addMatcher(apiSlice.endpoints.questionnaire.matchFulfilled, (state, action) => {
      if (!state.questionnaireCompleted) return;

      const returnOption = state.selectedReturnMethod?.id;

      if (!returnOption) return;

      const questionnaireFromApi = matchQuestionnaireTypeToApiResponse(
        action.payload.questionnaire[returnOption],
        state.questionnaireCompleted?.questionnaire.type,
      );

      if (!questionnaireFromApi) return;

      const mergedQuestions = mergeQuestionnaireAnswers(
        questionnaireFromApi,
        state.questionnaireCompleted?.questionnaire,
      );

      const control: InternalQuestionnaireState = {
        type: questionnaireFromApi.type,
        translatedType: questionnaireFromApi.translatedType,
        questions: mergedQuestions,
      };

      state.questionnaireControl = control;
    })
    .addMatcher(
      apiSlice.endpoints.marketConfig.matchFulfilled,
      (state, action) => {
        const options = action.payload.returnOptions?.options;
        if (!options) return;

        const config: ReturnMethodsMarketConfig = {} as ReturnMethodsMarketConfig;
        const isomKeys = Object.keys(options).filter((key) => key.includes('isom')) as IsomReturnOption[];

        isomKeys.forEach((key) => {
          const returnMethod = options[key];
          if (returnMethod) {
            config[key] = {
              isPickUp: Boolean(returnMethod.isPickUp),
              ...(returnMethod.holdUntilPaid && {
                holdUntilPaid: {
                  enabled: returnMethod.holdUntilPaid.enabled,
                  paymentExpirationTimeMs: returnMethod.holdUntilPaid.paymentExpirationTimeMs,
                },
              }),
            };
          }
        });

        state.returnMethodsMarketConfig = config;
      },
    )
    .addMatcher(
      apiSlice.endpoints.rescheduleReturn.matchFulfilled,
      (state, action) => {
        if (state.draft.reschedule) {
          state.draft.reschedule.successfulRescheduleTimeWindowId = action
            .payload.rescheduledWithTimeWindowId;
        }
      },
    );
};
