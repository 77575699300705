/* TODO RETURNS-6467
 look into frontend/co-worker-ui/src/utils/tokenUtils.ts
 we should probably reuse/refactor the validate token STATUS
*/
export const INIT_ERROR = {
  JWT_INVALID: {
    message: 'JWT is invalid or expired',
  },
  DEV_VIEW_MODE_MISSING_USER_ID: {
    message: 'Missing userID in URL for view mode',
  },
} as const;

type InitError = typeof INIT_ERROR & {
  INVALID_SAC_CONTENT: {
    message: string;
  };
};
export type InitErrorActionPayload = {
  error: keyof InitError;
  message: string;
};
