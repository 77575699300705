import { useTranslation } from 'react-i18next';
import ArticleInput from '../ArticleInput/ArticleInput';
import { ConditionOfGoods } from '../../../models/CustomerReturn';

interface ItemConditionOfGoodsProps {
  conditionOfGoods?: ConditionOfGoods;
}

function ItemConditionOfGoods({
  conditionOfGoods,
}: ItemConditionOfGoodsProps) {
  const { t } = useTranslation();

  if (!conditionOfGoods) return null;

  const translations: Record<ConditionOfGoods, string> = {
    ORIG_PACK: t('articles-reason-card.conditionOfGoods.originalPack'),
    ASSEMBLED: t('articles-reason-card.conditionOfGoods.assembled'),
    DAMAGED: t('articles-reason-card.conditionOfGoods.damaged'),
  };
  const value = translations[conditionOfGoods];

  return (
    <ArticleInput
      ariaLabel={`${t('articles-reason-card.aria-labels.conditionOfGoodsInput')}: ${value}`}
      className="relative xl:top-0"
      value={value}
    />
  );
}

export default ItemConditionOfGoods;
