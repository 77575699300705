import { ListViewItem, ListViewItemProps } from '@ingka/list-view';

export function ListItem({
  className,
  control,
  emphasised,
  inset,
  ...restListViewItemProps
}: ListViewItemProps) {
  return (
    <ListViewItem
      className="hover:bg-neutral-grey-100 [&_svg]:hidden"
      control="navigational"
      emphasised
      inset
      {...restListViewItemProps}
    />
  );
}
