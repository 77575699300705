import { ModalBody } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import copy from '@ingka/ssr-icon/paths/copy';
import ListView from '@ingka/list-view';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import { CustomListItem } from '../CustomListItem/CustomListItem';
import { useApplicableActions } from '../../../hooks/useApplicableActions';
import { useGetAppState } from '../../../store/features/appStateSlice/appStateSlice';
import { useGetSelectedReturnMethodState } from '../../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';
import { ViewModeApplicableActions } from '../../../models';
import { ViewRouteOnly } from '../../RouteOnly/RouteOnly';
import useAvailableActionsConfig from '../../../hooks/useAvailableActionsConfig/useAvailableActionsConfig';
import { ListItem } from '../ListItem';

const mapFailedPickupActions = (action: ViewModeApplicableActions): ViewModeApplicableActions => {
  switch (action) {
    case 'RE_SCHEDULE_FAILED':
      return 'RE_SCHEDULE';
    case 'CANCEL_FAILED':
      return 'CANCEL';
    default:
      return action;
  }
};

function ActionListItem({ action, actionsConfig, selectedReturnMethod }: {
  action: ViewModeApplicableActions;
  actionsConfig: Record<string, () => void>;
  selectedReturnMethod: any;
}) {
  const { t } = useTranslation();
  const actionType = mapFailedPickupActions(action);
  const hideRescheduleAction = actionType === 'RE_SCHEDULE' && !selectedReturnMethod?.isPickUp;

  if (hideRescheduleAction) return null;

  return (
    <ListItem
      key={action}
      onClick={actionsConfig[action]}
      title={t(`show-more.actions.${actionType.toLowerCase()}`)}
    />
  );
}

export function MoreMenuBody() {
  const { actions } = useApplicableActions();
  const { t } = useTranslation();
  const actionsConfig = useAvailableActionsConfig();
  const goToHistoryLog = useGoToSidebarPage('history-log');
  const selectedReturnMethod = useGetSelectedReturnMethodState('selectedReturnMethod');
  const selfServiceViewURL = useGetAppState('selfServiceViewURL');

  if (!actions && !selfServiceViewURL) return null;

  return (
    <ModalBody className="p-[0_!important]">
      <ListView id="show-more-list-view" size="large" showDivider={false}>
        {actions?.map((action) => (
          <ActionListItem
            key={action}
            action={action}
            actionsConfig={actionsConfig}
            selectedReturnMethod={selectedReturnMethod}
          />
        ))}
        <ViewRouteOnly>
          <ListItem
            onClick={goToHistoryLog}
            title={t('show-more.history-log.title')}
            key="HISTORY_LOG"
          />
        </ViewRouteOnly>
        {selfServiceViewURL && (
          <>
            {actions && (
              <hr className=" border-t-neutral-grey-200 border-t-3 mb-0 mt-0 mr-3 ml-3" />
            )}
            <CustomListItem
              icon={copy}
              label={t('commonly-reused.copyCustomerTracking')}
              labelSize="xs"
              onClick={() => copyToClipboard(selfServiceViewURL || '')}
              textTransition={t('commonly-reused.copied')}
            />
          </>
        )}
      </ListView>
    </ModalBody>
  );
}
