import type { AppEndPointBuilder } from '../apiSlice';
import type {
  CustomerReturnRequest,
  CustomerReturnResponse,
} from '../../../../models';

type SubmitReturnParams = {
  countryCode: Uppercase<string>;
  postBody: CustomerReturnRequest;
};

export const submitReturnBuilder = (builder: AppEndPointBuilder) => builder
  .mutation<CustomerReturnResponse, SubmitReturnParams>({
  query: ({ countryCode, postBody }) => ({
    body: postBody,
    method: 'POST',
    url: `/v2/${countryCode}/returns`,
  }),
});
