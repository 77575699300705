import { jwtDecode } from 'jwt-decode';
import { Config } from '../config';

interface Jwt {
  exp: number;
  aud: string;
}

type ValidatedJwt = {
  error: string | null;
  message: string;
};

export const STATUS = {
  INVALID: {
    error: 'JWT_INVALID',
    message: 'JWT is invalid or expired',
  },
  MISSING_RETURN_ID: {
    error: 'GET_RETURN_FAILED',
    message: 'returnId not set in URL',
  },
  MISSING_USER_ID: {
    error: 'GET_RETURN_FAILED',
    message: 'auth.userId not set in encoded argument',
  },
  VALID: {
    error: null,
    message: '',
  },
  UNKNOWN: {
    error: 'INIT_RETURN_FAILED',
    message: 'Init return failed',
  },
} as const;

export const validateJWT = (token: string, cfg: Config): ValidatedJwt => {
  try {
    const decodedJwt = jwtDecode<Jwt>(token);
    const isLamnaAudience = decodedJwt.aud === cfg?.VITE_LAMNA_AUDIENCE;
    const isEntraAudience = decodedJwt.aud === cfg?.VITE_ENTRA_AUDIENCE;
    const isExpired = (decodedJwt.exp ?? 0) * 1000 < Date.now();

    if (!(isLamnaAudience || isEntraAudience) || isExpired) {
      throw new Error('Invalid JWT');
    }

    return STATUS.VALID;
  } catch {
    return STATUS.INVALID;
  }
};
