import type { PayloadAction } from '@reduxjs/toolkit';
import { ItemAdded, PickedItem } from '@resolutions/item-picker-contract';
import {
  IssueCategory,
  mapIssueToSelectionOptions,
  PackageCondition,
  SelectionState,
} from '@resolutions/condition-of-goods';
import { PopPlugin } from '../../../hooks/useResolutionsScanner/validators/proofOfPurchase';
import { GetProductsResponse, Product } from '../api/returnAuthorization/products';
import { ProductInfo } from '../api/productInfo/getProductInfo';

export type BlindReturnItemFetching = {
  isFetching: boolean;
  itemLineId: string;
  itemNo: string;
  quantity: number;
};
type BlindReturn = {
  checked: boolean;
  items: (BlindReturnItemFetching | PopItem)[];
};
type ResolutionType = 'RETURN_INSTORE';
export type IssueType = {
  actor: string;
  category: IssueCategory,
  id: string;
  mainReasonCode: string;
  subReasonCode: string;
  title: string;
};
type ItemReturnInfo = {
  canBeAssembled: boolean;
  checked: boolean;
  issue: IssueType | null;
  conditionOfGoods: SelectionState | null;
  popId: string | null;
  resolution: ResolutionType | null;
};
export type PopItem = PickedItem & ItemReturnInfo;

export const isPoPItem = (
  item: BlindReturnItemFetching | PopItem,
): item is PopItem => !('isFetching' in item);

export type StatePop = {
  checked: boolean;
  fetchedResponse: GetProductsResponse | null;
  id: string;
  isFetching: boolean;
  items: PopItem[];
  type: PopPlugin;
};

export type OTCState = {
  actionsSidebarPopId: string | null;
  articleInSidebar: PopItem | null;
  blindReturn: BlindReturn;
  pops: StatePop[];
  pipState: {
    isOpen: boolean,
    purchaseId: string | null,
  }
};

export const updateItemsForPop = (state: OTCState, action: PayloadAction<ItemAdded>) => {
  const { item, purchaseId } = action.payload;
  state.pops = state.pops.map((pop) => {
    let itemInExistingPopItems = false;
    if (pop.id !== purchaseId) return pop;

    let updatedPop = {
      ...pop,
      items: pop.items.map((popItem) => {
        if (popItem.itemNo !== item.itemNo || popItem.itemLineId !== item.itemLineId) {
          return popItem;
        }

        itemInExistingPopItems = true;

        const quantity = popItem.quantity + item.quantity;
        const unitPaidPrice = popItem.price.unit.paidPrice;
        const unitPriceExclTax = unitPaidPrice.priceExclTax ?? null;
        const unitPriceInclTax = unitPaidPrice.priceInclTax ?? null;
        return {
          ...popItem,
          deliveryReference: item.deliveryReference,
          dimensions: item.dimensions,
          price: {
            ...popItem.price,
            total: {
              paidPrice: {
                priceExclTax: unitPriceExclTax ? unitPriceExclTax * quantity : null,
                priceInclTax: unitPriceInclTax ? unitPriceInclTax * quantity : null,
              },
            },
          },
          quantity,
        };
      }),
    };

    if (!itemInExistingPopItems) {
      const payloadItemInFetchedPopItems = pop.fetchedResponse?.products.find(
        (productObject) => productObject.product.lineId === item.itemLineId,
      );
      const canBeAssembled = payloadItemInFetchedPopItems
        ? payloadItemInFetchedPopItems.product.isAssemblyRequired
        : true;

      const popItemWithInitials: PopItem = {
        ...item,
        canBeAssembled,
        checked: false,
        conditionOfGoods: null,
        issue: null,
        popId: purchaseId,
        resolution: 'RETURN_INSTORE',
      };
      updatedPop = {
        ...pop,
        items: [
          ...pop.items,
          popItemWithInitials,
        ],
      };
    }

    return updatedPop;
  });
};

export const filterBlindReturnItemsByItemNo = (
  state: OTCState,
  filterItemNo: string | undefined,
) => {
  if (!filterItemNo) return;

  const updatedBlindReturnItems = state.blindReturn.items.filter(
    (item) => item.itemNo !== filterItemNo,
  );
  state.blindReturn.items = updatedBlindReturnItems;
};

export const removeDots = (text: string) => text.replace(/\./g, '');

type MapFetchedProductToStateItemProps = {
  deliveryReference?: string;
  itemLineId: string;
  product: Product | ProductInfo;
  quantity?: number;
};
export const mapFetchedProductToStateItem = ({
  deliveryReference = '0',
  itemLineId,
  product,
  quantity = 1,
}: MapFetchedProductToStateItemProps) => {
  const productPrice = 'price' in product ? product.price : null;
  const unitPriceExclTax = productPrice?.priceExclTax ?? null;
  const unitPriceInclTax = productPrice?.priceInclTax ?? null;

  return {
    canBeAssembled: product.isAssemblyRequired,
    currencyCode: productPrice?.currencyCode ?? '',
    deliveryReference,
    description: product?.type ?? '', // not received in fetched product
    dimensions: null,
    itemNo: removeDots(product.productNumber),
    itemLineId: 'lineId' in product ? product.lineId : itemLineId,
    itemType: product.itemType ?? 'ART',
    name: product.name ?? 'No name',
    price: {
      total: {
        paidPrice: {
          priceExclTax: unitPriceExclTax ? unitPriceExclTax * quantity : null,
          priceInclTax: unitPriceInclTax ? unitPriceInclTax * quantity : null,
        },
      },
      unit: {
        paidPrice: {
          priceExclTax: unitPriceExclTax,
          priceInclTax: unitPriceInclTax,
        },
      },
    },
    productImage: {
      alt: product.media?.alt ?? null,
      url: product.media?.variants.S2 ?? '',
    },
    quantity,
  };
};

const setConditionOfGoodsSingleArticle = (
  state: OTCState,
  action: PayloadAction<SelectionState>,
  article: PopItem,
) => {
  const enrichItemWithSelection = (item: PopItem) => {
    if (item.itemLineId !== article.itemLineId) return item;

    return {
      ...item,
      conditionOfGoods: action.payload,
    };
  };

  if (article.popId !== null) {
    state.pops = state.pops.map((pop) => {
      if (pop.id !== article?.popId) return pop;

      return {
        ...pop,
        items: pop.items.map(enrichItemWithSelection),
      };
    });
  } else {
    state.blindReturn.items = state.blindReturn.items.map(
      (item) => enrichItemWithSelection(item as PopItem),
    );
  }

  state.articleInSidebar = {
    ...article,
    conditionOfGoods: action.payload,
  };
};

const setConditionOfGoodsBulkArticles = (
  state: OTCState,
  action: PayloadAction<SelectionState>,
  sidebarPopId: string | null,
) => {
  const enrichItemWithBulkSelection = (item: PopItem) => {
    if (!item.checked || item.itemType === 'SGR' || !item.issue) return item;

    const { isAssembled, packageCondition } = action.payload;
    const { openDamagedDisabled, unopenedDisabled } = mapIssueToSelectionOptions(
      false,
      item.issue.category,
    );
    const canSetConditionOfGoods = (!unopenedDisabled
      && packageCondition === PackageCondition.UNOPENED)
      || (!openDamagedDisabled && packageCondition === PackageCondition.OPEN_DAMAGED);

    if (!canSetConditionOfGoods) return item;

    return {
      ...item,
      conditionOfGoods: {
        isAssembled: item.canBeAssembled ? isAssembled : false,
        packageCondition,
      },
    };
  };

  if (sidebarPopId !== null) {
    state.pops = state.pops.map((pop) => {
      if (pop.id !== sidebarPopId) return pop;

      return {
        ...pop,
        items: pop.items.map(enrichItemWithBulkSelection),
      };
    });
  } else {
    state.blindReturn.items = state.blindReturn.items.map(
      (item) => enrichItemWithBulkSelection(item as PopItem),
    );
  }
};

export const setConditionOfGoods = (state: OTCState, action: PayloadAction<SelectionState>) => {
  const { actionsSidebarPopId, articleInSidebar } = state;
  if (articleInSidebar) {
    setConditionOfGoodsSingleArticle(state, action, articleInSidebar);
  } else {
    setConditionOfGoodsBulkArticles(state, action, actionsSidebarPopId);
  }
};

const setIssueSingleArticle = (
  state: OTCState,
  action: PayloadAction<{ issue: IssueType }>,
  article: PopItem,
) => {
  const enrichItemWithIssue = (item: PopItem) => {
    if (item.itemLineId !== article.itemLineId) return item;
    return {
      ...item,
      issue: action.payload.issue,
      conditionOfGoods: null,
    };
  };
  const isPopArticle = article.popId !== null;
  if (isPopArticle) {
    state.pops = state.pops.map((pop) => {
      if (pop.id !== article.popId) return pop;
      return {
        ...pop,
        items: pop.items.map(enrichItemWithIssue),
      };
    });
  } else {
    state.blindReturn.items = state.blindReturn.items.map(
      (item) => enrichItemWithIssue(item as PopItem),
    );
  }

  state.articleInSidebar = {
    ...article,
    issue: action.payload.issue,
  };
};

const setIssueBulkArticles = (
  state: OTCState,
  action: PayloadAction<{ issue: IssueType }>,
  sidebarPopId: string | null,
) => {
  const enrichItemWithBulkIssue = (item: PopItem) => {
    if (!item.checked) return item;
    return {
      ...item,
      issue: action.payload.issue,
      conditionOfGoods: null,
    };
  };
  const isPopArticle = sidebarPopId !== null;
  if (isPopArticle) {
    state.pops = state.pops.map((pop) => {
      if (pop.id !== sidebarPopId) return pop;

      return {
        ...pop,
        items: pop.items.map(enrichItemWithBulkIssue),
      };
    });
  } else {
    state.blindReturn.items = state.blindReturn.items.map(
      (item) => enrichItemWithBulkIssue(item as PopItem),
    );
  }
};

export const setArticleIssue = (state: OTCState, action: PayloadAction<{ issue: IssueType }>) => {
  const { actionsSidebarPopId, articleInSidebar } = state;

  if (articleInSidebar) {
    setIssueSingleArticle(state, action, articleInSidebar);
  } else {
    setIssueBulkArticles(state, action, actionsSidebarPopId);
  }
};
