import { PriceItem } from 'shared-frontend';
import ProductIdentifier from '@ingka/product-identifier';
import SSRIcon from '@ingka/ssr-icon';
import imageStrikeThroughIcon from '@ingka/ssr-icon/paths/image-strikethrough';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { useMarketLocale } from '../../../hooks/useMarketLocale';
import { getPrices } from '../ArticlesReasonCardUtils';
import { ArticleListItemProps } from '../types';

interface ItemDetailsProps {
  article: ArticleListItemProps;
  withNoPriceWarning?: boolean;
}
function ItemDetails({ article, withNoPriceWarning = false }: ItemDetailsProps) {
  const { media, name, productDescription } = article;
  const { priceExclTax, priceInclTax, currencyCode } = getPrices(article, 'unitPrice');
  const showNoPriceWarning = withNoPriceWarning && (!priceExclTax || !priceInclTax);

  const productNumber = article.productNumber ?? article.itemNo;
  const amount = (priceExclTax || priceInclTax);
  const image = article.itemType === 'ART' && media?.variants.S2
    ? { alt: media?.alt, src: media?.variants.S2 }
    : undefined;

  const { t } = useTranslation();

  const { locale } = useMarketLocale();

  const translations: Record<string, string> = {
    Pieces: `/${t('articles-reason-card.units.unit')}`,
    Meters: `/${t('articles-reason-card.units.meters')}`,
    PriceNotAvailable: t('articles-reason-card.quantityDeviation.priceUnavailable'),
  };
  const subscriptLabel = typeof article.unit !== 'undefined' ? translations[article.unit] : undefined;

  return (
    <div className="flex overflow-hidden">
      <div className="pr-4 pb-[30px]">
        <div className="flex justify-center items-center h-[96px] w-[96px] border-[1px] border-neutral-grey-200">
          {image ? (
            <img className="max-w-full" src={image.src} alt={image.alt} />
          ) : (
            <SSRIcon
              paths={imageStrikeThroughIcon}
              data-testid="strike-through-icon"
            />
          )}
        </div>
      </div>

      <div className="flex flex-col justify-between min-w-0">
        <Text headingSize="xs">{name}</Text>
        <div>
          {productDescription && (
            <Text
              bodySize="m"
              className="overflow-hidden text-ellipsis whitespace-nowrap"
              tagName="p"
              data-testid="item-description"
              title={productDescription}
            >
              {productDescription}
            </Text>
          )}
          {(!currencyCode || showNoPriceWarning) ? (
            <Text headingSize="s">
              {translations.PriceNotAvailable}
            </Text>
          ) : (
            <PriceItem
              amount={amount ?? 0}
              currencyCode={currencyCode}
              locale={locale}
              fixedSize
              size="small"
              subscriptLabel={subscriptLabel}
            />
          )}
        </div>
        <ProductIdentifier className="mt-1" value={productNumber} subtle />
      </div>
    </div>
  );
}

export default ItemDetails;
