import { ModalFooter } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import Button from '@ingka/button';
import { useCloseSidebar, useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';
import { useGetOTCState } from '../../../store/features/OTCSlice/OTCSlice';
import { useShowOTCArticleSidebar } from '../../../store/features/standAloneActions/actions';

function Wrapper({ children }: { children: React.ReactNode }) {
  return <ModalFooter className="modal-footer--dual-action" renderBorder>{children}</ModalFooter>;
}

export function OTCIssuePickerFooter() {
  const { t } = useTranslation();
  const close = useCloseSidebar();
  const articleInSidebar = useGetOTCState('articleInSidebar');
  const showOTCArticleSidebar = useShowOTCArticleSidebar();
  const showBulkConditionOfGoodsSidebar = useGoToSidebarPage('otc-condition-of-goods');

  const onPrimaryButtonClick = () => {
    if (!articleInSidebar) {
      showBulkConditionOfGoodsSidebar();
      return;
    }

    const { itemType } = articleInSidebar;

    if (itemType === 'SGR') {
      close();
      return;
    }

    showOTCArticleSidebar(articleInSidebar, 'otc-condition-of-goods');
  };

  return (
    <Wrapper>
      <Button text={t('commonly-reused.close')} onClick={close} />
      <Button
        type="primary"
        disabled={false}
        text={t('commonly-reused.continue')}
        onClick={onPrimaryButtonClick}
      />
    </Wrapper>
  );
}
