import type { AppEndPointBuilder } from '../apiSlice';
import type {
  CustomerReturnResponse,
  GetCustomerReturnResponse,
} from '../../../../models';

type GetCustomerReturnApiConfig = {
  countryCode: string;
  returnId: string;
};

const fetchCustomerReturnApi = (
  countryCode: string,
  returnId: CustomerReturnResponse['returnId'],
) => ({ url: `/v2/${countryCode}/return-views/cw/${returnId}`, method: 'GET' });

export const getCustomerReturnBuilder = (builder: AppEndPointBuilder) => builder.query<
GetCustomerReturnResponse, GetCustomerReturnApiConfig>({
  query: (config: GetCustomerReturnApiConfig) => {
    const { countryCode, returnId } = config;
    const { url, method } = fetchCustomerReturnApi(countryCode, returnId);
    return { url, method };
  },
});
