import { ApiRespType, IsomReturnOption } from 'shared-frontend';
import type { AppEndPointBuilder } from '../apiSlice';

type QuestionnaireApiConfig = {
  countryCode: string,
  languageCode: Lowercase<string>,
  postCode: string,
};

const questionnaireApi = ({
  countryCode,
  languageCode,
  postCode,
}: QuestionnaireApiConfig) => (`/v1/${countryCode}/${languageCode}/postalcodes/${postCode}/return-options/questionnaire`);

export const questionnaireBuilder = (builder: AppEndPointBuilder) => builder.query<
ApiRespType<IsomReturnOption>['questionnaire'],
QuestionnaireApiConfig>({
  query: (config: QuestionnaireApiConfig) => questionnaireApi(config),
});
