import { ModalBody } from '@ingka/modal';
import { QuestionnaireComponent } from 'shared-frontend';
import { useTranslation } from 'react-i18next';
import { useGetAppState } from '../../../store/features/appStateSlice/appStateSlice';
import { useGetSidebarState } from '../../../store/features/sidebar/sidebarSlice';
import { useQuestionnaireControl, useSetQuestionnaireValid, useGetSelectedReturnMethodState } from '../../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useQuestionnaireForReturnMethod } from '../../../store/features/api/questionnaire/hooks';

export function QuestionnairePage() {
  const { t } = useTranslation();
  const open = useGetSidebarState('open');
  const returnReference = useGetAppState('returnReference');
  const selectedReturnMethod = useGetSelectedReturnMethodState('selectedReturnMethod');
  const setQuestionnaireValid = useSetQuestionnaireValid();

  const questionnaire = useQuestionnaireForReturnMethod(selectedReturnMethod?.id);
  const {
    questionnaireControlState,
    setQuestionnaireControl,
  } = useQuestionnaireControl();

  if (!questionnaire || !open) {
    return null;
  }

  const questionnaireLabels = {
    continueButton: t('commonly-reused.continue'),
    livingArrangement: t('questionnaire-sidebar.livingArrangement'),
  };

  const questionLabels = {
    additionalInfo: t('questionnaire-sidebar.additional-info-input.label'),
    answerLabels: {
      na: t('questionnaire-sidebar.na'),
      yes: t('questionnaire-sidebar.yes'),
      no: t('questionnaire-sidebar.no'),
    },
    chooseOption: t('commonly-reused.chooseAnOption'),
    maxLength: t('questionnaire-sidebar.additional-info-input.maxlengthError'),
    optionalField: t('questionnaire-sidebar.additional-info-input.optional'),
    regex: {
      FLOOR_NO: t('questionnaire-sidebar.validation-errors.floorNo'),
    },
    required: t('commonly-reused.fieldRequired'),
  };

  const viewMode = Boolean(returnReference);

  return (
    <ModalBody>
      <QuestionnaireComponent
        onQuestionnaireChange={setQuestionnaireControl}
        onValidationChange={setQuestionnaireValid}
        questionLabels={questionLabels}
        questionnaire={questionnaire}
        questionnaireControlState={questionnaireControlState}
        questionnaireLabels={questionnaireLabels}
        viewMode={viewMode}
        withoutSubmitButton
      />
    </ModalBody>
  );
}
