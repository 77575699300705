import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ErrorFallback } from 'shared-frontend';
import { useTranslation } from 'react-i18next';
import { prepareAppSetup } from './setupUtils';
import { store } from './store/app/store';
import './style/global.scss';
import AppWithRouting from './components/app/AppWithRouting';
import { reportErrorToSentry } from './utils/reportErrorToSentry';

function ErrorComponent() {
  const { t } = useTranslation();
  return (
    <div data-testid="error-boundary" className="w-1/2 mx-auto p-6">
      <ErrorFallback title={t('error.fallback-title')} body={t('error.fallback-message')} />
    </div>
  );
}

export function AppWithProviders() {
  return (
    <StrictMode>
      <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
        <Provider store={store}>
          <AppWithRouting />
        </Provider>
      </Sentry.ErrorBoundary>
    </StrictMode>
  );
}
(async () => {
  try {
    const root = await prepareAppSetup();
    root.render(<AppWithProviders />);
  } catch (error) {
    reportErrorToSentry(error);
  }
})();
