import { lazy, Suspense } from 'react';
import { Route, Router } from 'wouter';
import { useIncludeFonts } from 'shared-frontend';
import Loading, { LoadingBall } from '@ingka/loading';
import { useTranslation } from 'react-i18next';
import useAppRoutes from '../../hooks/useAppRoutes';
import { AuthenticatedApp } from '../../AuthAndFoundationRenderApp';
import ViewReturn from './View/ViewReturn';
import CreateReturn from './Create/CreateReturn';
import { useMarketLocale } from '../../hooks/useMarketLocale';
import { Sidebar } from '../Sidebar/Sidebar';
import { Prompt } from '../Prompt/Prompt';
import { RouteComponent as AllOTCRoutes } from './Base/RouteComponent/RouteComponent';

const LazyBaseLamna = lazy(() => import('./Base/BaseLamna').catch(
  (error) => {
    throw new Error('Error lazy loading OTC', { cause: error });
  },
));

function LazyWithFallback() {
  const { t } = useTranslation();
  return (
    <Suspense fallback={(
      <Loading data-testid="loader" text={t('commonly-reused.loading')}>
        <LoadingBall />
      </Loading>
    )}
    >
      <LazyBaseLamna />
    </Suspense>
  );
}

export default function AppWithRouting() {
  const {
    base, create, embedded, view,
  } = useAppRoutes();
  const { languageCode } = useMarketLocale();
  useIncludeFonts(languageCode, import.meta.env.MODE === 'test');

  return (
    <AuthenticatedApp>
      <Router base={base}>
        <AllOTCRoutes component={LazyWithFallback} />
        <Route path={embedded} nest>
          <Route path={create} component={CreateReturn} />
          <Route path={view} component={ViewReturn} />
        </Route>
      </Router>
      <Sidebar />
      <Prompt />
    </AuthenticatedApp>
  );
}
