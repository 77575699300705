type OrderReference = {
  orderNumber: string,
  orderNumberSource: string,
  lineId: string
};

type ReceiptReference = {
  ctmDocumentId: string,
  ctmLineId: string,
};

export const itemTypeValues = ['ART', 'SGR'] as const;
export type ItemType = typeof itemTypeValues[number];

export const unitValues = ['Meters', 'Pieces'] as const;
export type Unit = typeof unitValues[number];

export const stateOfGoodsValues = ['ASSEMBLED', 'DAMAGED', 'ORIG_PACK'] as const;
export type StateOfGoods = typeof stateOfGoodsValues[number];

export type SacItem = SacArtItem | SacDeliveryItem | SacMissingArticleItem;

export type SacItemPrice = {
  amount: number,
  totalAmount: number,
  currency: string,
  includingTax: boolean,
  tax: SacTaxDetails[]
};

export type SacTaxDetails = {
  rate: number,
  type: string,
  code: string,
};

type SacItemBase = {
  itemNo: string,
  itemType: ItemType,
  quantity: number,
  price: SacItemPrice,
  originalPrice: SacItemPrice,
  orderReference?: OrderReference | null,
  receiptReference?: ReceiptReference | null,
  sacLineId: number | null,
  media?: Media,
  name?: string,
  type?: string
  productNumber?: string,
};

type SacDeliveryItem = SacItemBase & {
  itemType: 'SGR',
};

interface SacReturnReason {
  reasonCode: string;
  subReasonCode: string;
}

export type SacArtItem = SacItemBase & SacReturnReason & {
  itemType: 'ART',
  unit: Unit
  stateOfGoods: StateOfGoods
};

type SacMissingArticleItem = SacItemBase & SacReturnReason & {
  itemType: 'SGR',
  unit: Unit
  stateOfGoods: StateOfGoods
};

interface Media {
  alt: string,
  name: string,
  variants: ImageVariant
}

interface ImageVariant {
  S1?: string,
  S2?: string,
  S3?: string,
  S4?: string,
  S5?: string,
}
