import { Route, RouteProps } from 'wouter';
import useAppRoutes from '../../../../hooks/useAppRoutes';

type RouteComponentProps = {
  component?: RouteProps['component']; // Use this if rendering same component for both paths
  rootComponent?: RouteProps['component'];
  submitComponent?: RouteProps['component'];
};

export function RouteComponent({
  component, rootComponent, submitComponent,
}: RouteComponentProps) {
  const { otc } = useAppRoutes();

  const RootComponentRendered = component ?? rootComponent;
  const SubmitComponentRendered = component ?? submitComponent;

  return (
    <>
      <Route path={otc.seed} nest>
        <Route path={otc.root} component={RootComponentRendered} />
        <Route path={otc.submit} component={SubmitComponentRendered} />
      </Route>
      <Route path={otc.root} component={RootComponentRendered} />
      <Route path={otc.submit} component={SubmitComponentRendered} />
    </>
  );
}
