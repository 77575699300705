import Tooltip, { TooltipProps } from '@ingka/tooltip';

type TooltipWithConditionProps = {
  describedById: string;
  tooltipText: string | null;
} & Omit<TooltipProps, 'className' | 'describedById' | 'tooltipText'>;

function TooltipWithCondition({
  children, describedById, tooltipText, ...restToolTipProps
}: TooltipWithConditionProps) {
  return tooltipText ? (
    <Tooltip
      {...restToolTipProps}
      className="font-normal [&__.tooltip__body]:max-w-[17rem]"
      describedById={describedById}
      tooltipText={tooltipText}
    >
      {children}
    </Tooltip>
  ) : children;
}
export default TooltipWithCondition;
