import { ModalFooter } from '@ingka/modal';
import { SubmitTimeWindowButton, getDateLocale } from 'shared-frontend';
import {
  useGetSelectedReturnMethodDraft,
  useGetSelectedReturnMethodState,
} from '../../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useTimeWindowSet } from '../../../store/features/standAloneActions/actions';
import { useMarketLocale } from '../../../hooks/useMarketLocale';
import { useViewRoute } from '../../../hooks/useAppRoutes';

export function TimeWindowPageFooter() {
  const { languageCode, countryCode } = useMarketLocale();
  const draft = useGetSelectedReturnMethodDraft();
  const subOptionData = draft.selectedDateSubOptionData;

  const locale = getDateLocale(languageCode, countryCode);
  const setTimeWindow = useTimeWindowSet();
  const isView = useViewRoute();

  const originalTimeWindowId = draft.reschedule?.originalTimeWindow.timeWindowId;
  const selectedTimeWindowId = useGetSelectedReturnMethodState('selectedReturnMethod')?.timeWindow.timeWindowId;
  const currentSelectedTimeWindowId = subOptionData?.timeWindow.timeWindowId;

  if (!subOptionData?.timeWindow) {
    return null;
  }

  const noChangesToSave = selectedTimeWindowId === originalTimeWindowId
    && selectedTimeWindowId === currentSelectedTimeWindowId;

  return (
    <ModalFooter renderBorder>
      <SubmitTimeWindowButton
        disable={isView && noChangesToSave}
        locale={locale}
        onClick={() => setTimeWindow(isView && !noChangesToSave)}
        selectedDateSubOption={subOptionData.timeWindow}
      />
    </ModalFooter>
  );
}
