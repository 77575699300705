/* eslint-disable react/jsx-no-useless-fragment */
import { useTranslation } from 'react-i18next';
import Text from '@ingka/text';
import { ErrorFallback } from 'shared-frontend';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { useOTCRoute } from '../../hooks/useAppRoutes';

function CustomErrorMessage({ errorTitle, errorMessage, isInvalidSac }: {
  errorTitle: string;
  errorMessage: string;
  isInvalidSac: boolean;
}) {
  const isAuthenticated = useGetAppState('isAuthenticated');
  const { t } = useTranslation();
  return (
  /* TODO RETURNS-6467
    for now we need to skip auth messages because there is a race condition between
    the create return function being called and the authentication checks
    from queryParams when opening from SAMS
  */
    <>
      {isAuthenticated ? (
        <div className="text-center">
          <Text tagName="h1" headingSize="l" className="mb-8">{errorTitle}</Text>
          {isInvalidSac && <Text bodySize="l">{t('error.invalid-sac-message')}</Text>}
          <Text bodySize="l" className="pt-2">{errorMessage}</Text>
        </div>
      ) : null}
    </>
  );
}

export default function InitErrorMessage() {
  const { t } = useTranslation();
  const initError = useGetAppState('initError');
  const isOTCRoute = useOTCRoute();

  const isInvalidSac = initError?.error === 'INVALID_SAC_CONTENT';
  const errorTitle = isInvalidSac ? t('error.invalid-sac-title') : t('error.fallback-title');
  const errorMessage = initError?.message ?? t('error.fallback-message');

  /* TODO RETURNS-6467
    handle the different auth custom errors instead of using the same message for all of them
  */
  return (
    <>
      {
        isOTCRoute ? (
          <ErrorFallback
            title={t('error.fallback-title')}
            body={t('error.authentication-failed')}
            testId="authentication-failed"
          />
        ) : (
          <CustomErrorMessage
            errorTitle={errorTitle}
            errorMessage={errorMessage}
            isInvalidSac={isInvalidSac}
          />
        )
      }
    </>
  );
}
