import { useGetAppState } from './store/features/appStateSlice/appStateSlice';
import { useMarketConfigFetch } from './useMarketConfigFetch';
import { isValidatedMarketConfig } from './store/features/api/marketConfig/marketConfig';
import InitErrorMessage from './components/InitErrorMessage/InitErrorMessage';

interface App {
  children: JSX.Element | JSX.Element[] | null;
}

export function AuthenticatedApp({ children }: App) {
  const isAuthenticated = useGetAppState('isAuthenticated');
  const initError = useGetAppState('initError');
  const isAuthenticatedAndNoInitErrors = isAuthenticated && initError?.error === undefined;

  return isAuthenticatedAndNoInitErrors
    ? children
    : (<InitErrorMessage />);
}

export function MarketConfigFetch({ children }: App) {
  const {
    data: marketConfig,
  } = useMarketConfigFetch();

  return isValidatedMarketConfig(marketConfig)
    ? children
    : null;
}
