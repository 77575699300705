import { useEffect, useState } from 'react';
import Text, { TextProps } from '@ingka/text';
import SSRIcon, { SSRIconProps } from '@ingka/ssr-icon';

interface CustomListItemProps {
  icon?: SSRIconProps['paths']
  label: string
  labelSize: TextProps['headingSize']
  onClick?: () => void
  textTransition?: string,
}

interface TransitionTextProps {
  condition?: boolean
  labelSize: TextProps['headingSize']
  text: string
  transitionTo?: string
}

function TransitionText({
  text, transitionTo, labelSize, condition,
}: TransitionTextProps) {
  return (
    <Text
      className="flex-auto text-left "
      headingSize={labelSize}
      tagName="span"
    >
      {condition && transitionTo ? transitionTo : text}
    </Text>
  );
}

// Skapa recommends 5 to 10 seconds based on length of the text, keeping it at 5 for simplicity
// Setting it to small timeout for tests to have the tests run faster
const CLICK_TIMEOUT = import.meta.env.MODE === 'test' ? 100 : 5000;

export function CustomListItem({
  icon, label, labelSize, onClick, textTransition,
}: CustomListItemProps) {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!clicked) return () => { };
    const timeout = setTimeout(() => setClicked(false), CLICK_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [clicked]);

  const onClickHandler = () => {
    onClick?.();
    if (textTransition) setClicked(true);
  };

  return (
    <li className="hover:bg-neutral-grey-100 hover:underline flex flex-row">
      <button
        onClick={onClickHandler}
        type="button"
        className="w-full flex flex-row h-full pl-6 pr-6 pt-7 pb-7 "
      >
        <TransitionText
          condition={clicked}
          labelSize={labelSize}
          text={label}
          transitionTo={textTransition}
        />
        {icon && (
          <span className="flex flex-row justify-items-end">
            <SSRIcon paths={icon} ariaHidden />
          </span>
        )}
      </button>
    </li>
  );
}
