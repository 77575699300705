import InlineMessage from '@ingka/inline-message';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { useTranslation } from 'react-i18next';
import { Card, RefundAmount } from 'shared-frontend';
import { CardTitle } from '../CardTitle/CardTitle';
import { useMarketLocale } from '../../hooks/useMarketLocale';
import { useRefundCalculations } from '../../store/features/api/refundCalc/refundCalcHooks';

function Wrapper({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const ariaLabelledById = 'refund-summary-title-id';

  return (
    <Card
      ariaLabelledById={ariaLabelledById}
      title={(
        <CardTitle id={ariaLabelledById}>
          {t('refund-summary.title')}
        </CardTitle>
    )}
    >
      {children}
    </Card>
  );
}

function RefundSummaryCard(): React.ReactElement {
  const { t } = useTranslation();
  const { countryCode, languageCode } = useMarketLocale();
  const refund = useRefundCalculations();

  if (!refund) {
    return (
      <Wrapper>
        <InlineMessage
          subtle
          variant="negative"
          ssrIcon={informationCircle}
          body={t('refund-summary.noRefundCalcAvailable')}
          className=""
        />
      </Wrapper>
    );
  }

  const descriptionLabels = {
    purchaseTotal: t('refund-summary.totalValue'),
    serviceFee: t('refund-summary.collectionServiceCost'),
    refundTotal: t('refund-summary.estimatedRefundValue'),
  };

  const {
    collectionServiceCost, currencyCode, estimatedRefund, hasExchangeResolution, subTotal,
  } = refund;

  return (
    <Wrapper>
      <RefundAmount
        countryCode={countryCode}
        currencyCode={currencyCode}
        descriptionLabels={descriptionLabels}
        estimatedRefund={estimatedRefund || 0}
        languageCode={languageCode}
        refundAmount={subTotal || 0}
        serviceFee={collectionServiceCost || 0}
        totalRefundStrikeThrough={hasExchangeResolution}
        withVerticalMargin={false}
      />
    </Wrapper>
  );
}

export default RefundSummaryCard;
