import { ModalBody } from '@ingka/modal';
import { TimeWindowCal, ParsedTimeWindow } from 'shared-frontend';
import { useTranslation } from 'react-i18next';
import incorrect from '@ingka/ssr-icon/paths/incorrect';
import InlineMessage from '@ingka/inline-message';
import { SkeletonItem } from '../EntriesSkeleton';
import { useAvailableReturnMethodsResponse } from '../../app/initialFetchUtils';
import { useCreateRoute } from '../../../hooks/useAppRoutes';
import { useGetAvailableTimeWindowsFetch } from '../../app/View/useViewInitialFetch';
import {
  useGetSelectedReturnMethodDraft,
  useSetSelectedDateSubOptionDraft,
  useGetSelectedReturnMethodState,
} from '../../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useHandleReschedules } from '../../../hooks/useHandleReschedules';
import { useMarketLocale } from '../../../hooks/useMarketLocale';

function LoadingSkeleton({ show }: { show: boolean }) {
  const { t } = useTranslation();
  if (!show) {
    return null;
  }
  return (
    <div
      aria-busy
      aria-label={t('commonly-reused.loading')}
      data-testid="skeleton"
      role="progressbar"
    >
      <SkeletonItem />
    </div>
  );
}

function ErrorMessage({ title, body }: { title: string, body: string }) {
  return (
    <InlineMessage
      body={body}
      className="mt-4"
      ssrIcon={incorrect}
      title={title}
      variant="negative"
    />
  );
}

export function TimeWindowPage() {
  const { t } = useTranslation();
  const { countryCode, languageCode } = useMarketLocale();
  const { data: availableReturnMethods } = useAvailableReturnMethodsResponse();
  const { selectedReturnMethod: selectedReturnMethodDraft } = useGetSelectedReturnMethodDraft();
  const isCreateRoute = useCreateRoute();
  const selectedReturnMethod = useGetSelectedReturnMethodState('selectedReturnMethod');
  const { isLoading, isError } = useGetAvailableTimeWindowsFetch({ forceSkip: isCreateRoute });

  const {
    firstAvailableTimeWindowId,
    lastSuccessfullyScheduledTimeWindow,
    remainingTimeWindows,
  } = useHandleReschedules();

  const setDate = useSetSelectedDateSubOptionDraft();
  const onDayChange = ({ selectedDateSubOption }: {
    selectedDateSubOption: ParsedTimeWindow
  }) => setDate(selectedDateSubOption);

  const availableMethods = isCreateRoute
    ? availableReturnMethods
    : [
      {
        id: selectedReturnMethod?.id,
        availableTimeWindows: remainingTimeWindows,
        proposedTimeWindowId: firstAvailableTimeWindowId,
      },
    ];
  const canShowCalendar = isCreateRoute || (Object.keys(remainingTimeWindows || {}).length > 0);
  const selectedMethod = isCreateRoute ? selectedReturnMethodDraft : selectedReturnMethod;

  if (!selectedMethod?.timeWindow || !availableMethods) {
    return null;
  }

  const labels = {
    ariaCloseTxt: t('time-windows-sidebar.ariaClose'),
    headerTitle: t('time-windows-sidebar.title'),
    noTimeWindowsBody: t('time-windows-sidebar.timeWindowsUnavailableMessageBody'),
    noTimeWindowsTitle: t('time-windows-sidebar.timeWindowsUnavailableMessageTitle'),
    previousScheduledDate: t('time-windows-sidebar.previousScheduledDate'),
    servicesUnavailableBody: t('time-windows-sidebar.servicesUnavailableMessageBody'),
    servicesUnavailableTitle: t('time-windows-sidebar.servicesUnavailableMessageTitle'),
    showMoreDates: t('time-windows-sidebar.showMoreDates'),
    subOptionsTitle: t('time-windows-sidebar.subOptionsTitle'),
    tooltip: t('time-windows-sidebar.tooltip'),
  };

  const showNoTimeWindows = !isLoading && !isError && !canShowCalendar;
  const showServiceUnavailable = !isLoading && isError;

  return (
    <ModalBody>
      <LoadingSkeleton show={isLoading} />
      {showServiceUnavailable && (
        <ErrorMessage
          body={labels.servicesUnavailableBody}
          title={labels.servicesUnavailableTitle}
        />
      )}
      {showNoTimeWindows && (
        <ErrorMessage
          body={labels.noTimeWindowsBody}
          title={labels.noTimeWindowsTitle}
        />
      )}
      {canShowCalendar && (
        <TimeWindowCal
          availableReturnMethods={availableMethods}
          countryCode={countryCode}
          labels={labels}
          languageCode={languageCode}
          onDateSubOptionChange={onDayChange}
          originallyScheduled={lastSuccessfullyScheduledTimeWindow}
          selectedReturnMethod={selectedMethod}
        />
      )}
    </ModalBody>
  );
}
