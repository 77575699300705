import Text from '@ingka/text';

type CardTitleProps = {
  children: React.ReactNode;
  className?: string;
  id: string;
};

export function CardTitle({ children, className, id }: CardTitleProps) {
  return (
    <Text className={className} headingSize="m" id={id} tagName="h2">
      {children}
    </Text>
  );
}
