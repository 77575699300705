import { useTranslation } from 'react-i18next';
import { DataCard } from 'shared-frontend';
import { CardTitle } from '../CardTitle/CardTitle';
import { useRefundMethodConfig } from './useRefundMethodConfig';

function RefundMethodCard(): React.ReactElement {
  const { t } = useTranslation();
  const { content, config } = useRefundMethodConfig();

  const ariaLabelledById = 'return-summary-title-id';

  return (
    <DataCard
      ariaLabelledById={ariaLabelledById}
      className="text-sm mb-12"
      content={content}
      config={config}
      title={(
        <CardTitle id={ariaLabelledById}>
          {t('refund-method-card.title')}
        </CardTitle>
      )}
    />
  );
}

export default RefundMethodCard;
