import { Sac } from './Sac';
import { ReturnPrice } from './Price';

export const MISSING_ARTICLE_ITEM_NO = '20000080';

const itemTypeValues = ['ART', 'SGR'] as const;
export type ItemType = typeof itemTypeValues[number];
const unitValues = ['Meters', 'Pieces'] as const;
export type Unit = typeof unitValues[number];
const conditionOfGoodsValues = ['ASSEMBLED', 'DAMAGED', 'ORIG_PACK'] as const;
export type ConditionOfGoods = typeof conditionOfGoodsValues[number];

type OrderReference = {
  orderNumber: string,
  orderNumberSource: string,
  lineId: string
};
type ReceiptReference = {
  ctmDocumentId: string,
  ctmLineId: string,
};

interface ImageVariant {
  S1?: string,
  S2?: string,
  S3?: string,
  S4?: string,
  S5?: string,
}

export interface Media {
  alt: string,
  name: string,
  variants: ImageVariant
}

export type ReturnReason = {
  mainReasonCode: string;
  subReasonCode: string;
};

export type ReturnItemBase = {
  id: string;
  itemNo: string;
  itemType: ItemType;
  quantity: number;
  price: ReturnPrice;
  originalPrice: ReturnPrice;
  orderReference?: OrderReference;
  receiptReference?: ReceiptReference;
  sourceLineRef?: string;
};

export interface ReturnDeliveryItem extends ReturnItemBase {
  itemType: 'SGR';
}

export interface ReturnArtItem extends ReturnItemBase {
  conditionOfGoods: ConditionOfGoods;
  itemType: 'ART';
  returnReason: ReturnReason;
  unit: Unit;
}

export interface ReturnMissingArticleItem extends ReturnItemBase {
  conditionOfGoods: ConditionOfGoods;
  itemNo: typeof MISSING_ARTICLE_ITEM_NO;
  itemType: 'SGR';
  returnReason: ReturnReason;
  unit: Unit;
}

export interface ReceivedArticleItem extends
  ReturnArtItem,
  Omit<ReceivedItem, 'itemType'> { }

export interface ReceivedMissingArticleItem extends
  ReturnMissingArticleItem,
  Omit<ReceivedItem, 'itemNo' | 'itemType'> { }

export type CustomerReturnItem = ReturnMissingArticleItem | ReturnArtItem | ReturnDeliveryItem;

type ExchangeResolution = {
  deliveryFromDateTime?: string;
  deliveryToDateTime?: string;
  orderNumber: string;
  orderNumberSource: string;
};
export type PaidBy = 'IKEA' | 'TSP' | 'CUSTOMER' | 'INVALID';

export type CustomerReturn = Omit<Sac, 'auth' | 'isCredit' | 'items' | 'paidByIkea' | 'paidByTsp'> & {
  isCreditPayment: boolean;
  paidBy: PaidBy;
  items: CustomerReturnItem[];
  exchangeResolution?: ExchangeResolution;
};

type Receiving = {
  okQuantity: number;
  damagedQuantity: number;
  unSellableQuantity: number;
  notInSellableRangeQuantity: number;
  salesStopQuantity: number;
};

export type StoFlow = Receiving;

export type CdcFlow = Omit<Receiving, 'notInSellableRangeQuantity' | 'salesStopQuantity'>;

export type ReceivedItem = {
  id: string;
  itemNo: string;
  itemType: ItemType;
  unit: Unit;
  expectedQuantity: number;
  receiving: StoFlow | CdcFlow;
};
