import type { AppEndPointBuilder } from '../apiSlice';
import { Media } from '../../../../models/CustomerReturn';

type ProductPrice = {
  currencyCode: string;
  priceExclTax: number | null;
  priceInclTax: number | null;
};

export type Product = {
  isAssemblyRequired: boolean;
  itemType?: 'ART' | 'SGR' | 'SPR';
  lineId: string;
  media?: Media;
  name?: string;
  price?: ProductPrice;
  productNumber: string;
  quantity?: number;
  type?: string;
  unitCode?: 'PIECE' | 'METER';
  units?: number;
};

export type ProductAuthorization = {
  authorizations: Authorization[];
  product: Product;
  resolutionsTrail: ResolutionsTrail[];
};

type Authorization = {
  authorizationRule: string;
  classification: 'APPROVED' | 'BLOCK' | 'WARN';
  description?: string;
};

type SourceReference = {
  type: 'CUSTOMER_RETURN' | 'SAC';
  value: string;
};

type ResolutionsTrail = {
  createdDate: string;
  quantity?: number;
  sourceReference: SourceReference;
  status: 'CLOSED' | 'IN_PROGRESS';
};

export type GetProductsResponse = {
  products: ProductAuthorization[];
  purchaseAuthorizations: Authorization[];
  resolutionsTrail: ResolutionsTrail[];
};

type GetProductsConfig = {
  countryCode: string;
  languageCode: string;
  purchaseId: string;
};

export const getReturnAuthorizationProductsBuilder = (builder: AppEndPointBuilder) => builder.query<
GetProductsResponse, GetProductsConfig>({
  query: ({ countryCode, languageCode, purchaseId }) => ({
    method: 'GET',
    url: `v2/${countryCode}/${languageCode}/purchases/${purchaseId}/products`,
  }),
});
